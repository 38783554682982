<template>
  <div class="info-one">
    <van-cell-group title="基本资料">
      <van-field :disabled="disabled" label="证券账号" v-model="editItem.stockAccount" input-align="right" placeholder="请输入证券账号"/>
      <van-field :disabled="disabled" label="姓名(中文)" v-model="editItem.cnName" input-align="right" placeholder="请输入姓名(中文)"/>
      <van-field :disabled="disabled" label="姓名(英文/拼音)" label-width="120px" v-model="editItem.enName" input-align="right" placeholder="请输入姓名(英文/拼音)"/>
      <van-field :disabled="disabled" label="手机号" v-model="editItem.changeTel" input-align="right" placeholder="请输入手机号" />
      <van-field :disabled="disabled" label="住宅电话号码" label-width="120px" v-model="editItem.homeTel" input-align="right" placeholder="请输入住宅电话号码"/>
      <van-field :disabled="disabled" label="办公室电话号码" label-width="120px" v-model="editItem.officeTel" input-align="right" placeholder="请输入办公室电话号码"/>
      <van-field :disabled="disabled" label="传真号码" v-model="editItem.faxNum" input-align="right" placeholder="请输入传真号码"/>
      <van-cell title="E-mail">
        <van-radio-group :disabled="disabled" class="check-group" v-model="editItem.emailOperate">
          <van-radio :name="0" shape="square">新增</van-radio>
          <van-radio :name="1" shape="square">变更</van-radio>
        </van-radio-group>
      </van-cell>
      <van-field :disabled="disabled" label="" v-model="editItem.email" input-align="right" placeholder="邮箱号码恕不接受。P.O.Box will NOT be accepted."/>
      <van-cell title="地址">
        <van-radio-group :disabled="disabled" class="check-group" v-model="editItem.addressType">
          <van-radio style="margin-bottom: 10px;" :name="0" shape="square">住宅</van-radio>
          <van-radio style="margin-bottom: 10px;" :name="1" shape="square">办公室</van-radio>
          <van-radio :name="2" shape="square">通讯地址</van-radio>
          <van-radio :name="3" shape="square">公司注册地址</van-radio>
        </van-radio-group>
      </van-cell>
      <van-field :disabled="disabled" label="" v-model="editItem.address" input-align="right" :placeholder="`请输入${['住宅', '办公室', '通讯地址', '公司注册地址'][editItem.addressType] || ''}`"/>
      <van-cell title="银行户口">
        <van-radio-group :disabled="disabled" class="check-group" v-model="editItem.bankOperate">
          <van-radio :name="0" shape="square">新增</van-radio>
          <van-radio :name="1" shape="square">变更</van-radio>
        </van-radio-group>
      </van-cell>
      <van-field :disabled="disabled" v-model="editItem.bankName" input-align="right" :placeholder="`请输入开户行`">
        <template #label>
          <div style="margin-left: 15px;">开户行</div>
        </template>
      </van-field>
      <van-field :disabled="disabled" v-model="editItem.bankAccountName" input-align="right" :placeholder="`请输入银行账户名称`">
        <template #label>
          <div style="margin-left: 15px;">银行账户名称</div>
        </template>
      </van-field>
      <van-field :disabled="disabled" v-model="editItem.bankAccountNum" input-align="right" :placeholder="`请输入银行账户号码`">
        <template #label>
          <div style="margin-left: 15px;">银行账号号码</div>
        </template>
      </van-field>
      <van-field
        :disabled="disabled"
        v-model="editItem.other"
        type="textarea"
        label="其他"
        input-align="right"
        placeholder="请输入"
        rows="2"
        autosize
      />
    </van-cell-group>
    <div style="margin-top: 10px; font-size: 14px; padding: 4px">
      如搬迁往另一国家，请附上新的W-8表格。New W-8BEN Form is required if you relocated to another country.
    </div>
    <input
      type="file"
      class="image"
      style="visibility: hidden; position: absolute; z-index: -1;"
      id="uploadImage"
      @change="selectFileImage($event)"
    />
    <div style="margin-left: 15px;margin-top: 10px;">
      <div v-for="item in fileList" :key="item.fileId" style="padding-bottom: 5px;padding-top: 5px; display: flex; justify-content: space-between">
        <div>{{ item.fileName }}</div>
        <a v-if="!disabled" style="margin-right: 20px; color: red;" @click="deleteFile(item.fileId)">删除</a>
      </div>
    </div>
    <label :for="disabled ? '' : 'uploadImage'">
      <van-cell-group>
        <van-cell title="上传附件" value="" is-link>点击上传</van-cell>
      </van-cell-group>
    </label>
    <van-cell-group title="如已确认更改内容，请签名" style="margin: 0px 20px;">
      <div class="image" @click="handleShowSignatrue">
        <van-image
          v-if="subAccountSign"
          width="250px"
          height="100px"
          fit="contain"
          :disabled="disabled"
          :src="subAccountSign"
        />
        <div v-else style="height: 100px; line-height: 100px">点击签名</div>
      </div>
    </van-cell-group>
    <div style="width: 100%;text-align: center;margin-bottom: 40px;">
      <van-button class="btn" @click="handleSubmit" type="primary">
        {{ disabled ? '确定' : '提交'}}
      </van-button>
    </div>
    <div class="signatrueMask" v-show="showSignatrue">
      <div class="signatrue" @touchmove.stop.prevent>
        <canvas ref="signatrue" class="canvas" />
        <div class="btns">
          <van-button type="danger" class="btn" @click="handleClearSignatrue"> 清除 </van-button>
          <van-button type="default" class="btn" @click="handleCancelSignatrue"> 取消 </van-button>
          <van-button type="primary" class="btn" @click="handleOkSignatrue"> 确定 </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignaturePad from "signature_pad";
import {
  Button, Icon, CellGroup, Field, Cell, Image as VanImage,
  Checkbox, CheckboxGroup, RadioGroup, Radio,
} from 'vant';
import { useStore } from 'vuex';
import { onMounted, reactive, ref, toRaw } from 'vue';
import httpApi from '../../utils/httpApi';

export default {
  name: 'Home',
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Icon.name]: Icon,
    [VanImage.name]: VanImage,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },
  data() {
    return {};
  },
  methods: {
    async selectFileImage(event) {
      const file = event.target.files[0];
      if (file.size > 5 * 1024 * 1024) {
        this.$toast('文件大小超过5MB，请重新上传！');
        return;
      }
      const data = new FormData();
      data.append('file', file, file.name);
      data.append('fileName', file.name);
      const { data: result } = await httpApi.changeInfoUploadFile(data);
      this.fileList.push(result.data);
    },
    deleteFile(fileId) {
      this.fileList = this.fileList.filter(item => item.fileId !== fileId);
    },
    // 显示签名
    handleShowSignatrue() {
      if (this.disabled) return;
      this.showSignatrue = true;
      this.$refs.signatrue.width = window.innerWidth - 44;
      this.$refs.signatrue.height = window.innerHeight - 46;
      // 获取到签名数据之后执行
      this.signaturePad = new SignaturePad(this.$refs.signatrue, {
        minWidth: 1,
        maxWidth: 5,
      });
    },

    // 签名确认
    handleOkSignatrue() {
      const data = this.signaturePad && this.signaturePad.toDataURL();
      if (this.signaturePad.isEmpty()) {
        this.subAccountSign = "";
        this.showSignatrue = false;
      } else {
        this.rotateBase64Image(data);
      }
    },

    // 签名清除
    handleClearSignatrue() {
      return this.signaturePad.clear();
    },

    // 签名取消
    handleCancelSignatrue() {
      this.showSignatrue = false;
    },

    // 图片旋转
    rotateBase64Image(base64data) {
      const image = new Image();
      image.src = base64data;

      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.height = image.width;
        canvas.width = image.height;
        const ctx = canvas.getContext("2d");
        ctx.translate(Math.floor(image.height / 2), Math.floor(image.width / 2));
        ctx.rotate((-90 * Math.PI) / 180);
        ctx.drawImage(image, -image.width / 2, -image.height / 2);
        this.showSignatrue = false;
        this.subAccountSign = canvas.toDataURL();
      };
    },
    async handleSubmit() {
      if (this.disabled) {
        this.$router.push({ path: '/change/result' });
        return;
      }
      if (!this.subAccountSign) {
        this.$toast('请填写签名');
        return;
      }
      const params = toRaw(this.editItem);
      params.signature = this.subAccountSign;
      params.attachments = JSON.stringify(this.fileList);
      await httpApi.changeInfoSubmit(params);
      this.$router.push({ path: '/change/result' });
    }
  },
  setup() {
    const showSignatrue = ref(false);
    const subAccountSign = ref(null);
    const fileList = ref([]);
    const signaturePad = null;
    const disabled = ref(false);
    const editItem = reactive({
      id: '',
      stockAccount: '',
      cnName: '',
      enName: '',
      changeTel: '',
      homeTel: '',
      officeTel: '',
      faxNum: '',
      email: '',
      emailOperate: null,
      address: '',
      addressType: null,
      bankOperate: null,
      bankName: '',
      bankAccountName: '',
      bankAccountNum: '',
      other: '',
      attachments: [],
      signature: '',
    });
    onMounted(async () => {
      const store = useStore();
      store.commit('setTitleValue', '更改账户资料');
      store.commit('setStepsShow', false);
      const stateResult = await httpApi.changeInfoGetState();
      const result = await httpApi.changeInfoGetData();
      if (stateResult.data.changeState === 4) {
        Object.keys(result.data).forEach(key => {
          if (editItem[key] !== undefined) {
            editItem[key] = result.data[key];
          }
        });
        editItem.changeTel = result.data.changeTel || result.data.tel;
        fileList.value = JSON.parse(result.data.attachments || '[]');
        subAccountSign.value = result.data.signature;
      } else if (stateResult.data.changeState === 1 || stateResult.data.changeState === 2) {
        Object.keys(result.data).forEach(key => {
          if (editItem[key] !== undefined) {
            editItem[key] = result.data[key];
          }
        });
        editItem.changeTel = result.data.changeTel || result.data.tel;
        disabled.value = true;
        fileList.value = JSON.parse(result.data.attachments || '[]');
        subAccountSign.value = result.data.signature;
      } else {
        const { stockAccount, cnName, enName, tel } = result.data;
        editItem.stockAccount = stockAccount;
        editItem.cnName = cnName;
        editItem.enName = enName;
        editItem.changeTel = tel;
      }
    });
    return {
      editItem,
      signaturePad,
      showSignatrue,
      subAccountSign,
      fileList,
      disabled,
    };
  },
};
</script>

<style lang="less">
.info-one {
  text-align: left;
  .van-cell__title {
    width: 120px;
    flex-basis: 120px;
    flex-shrink: 0;
    flex-grow: 0;
  }
  .check-group {
    width: 300px;
    display: flex;
    justify-content: space-around;
    align-items: space-around;
    flex-wrap: wrap;
    & > div {
      flex-basis: 50%;
    }
  }
  .icon {
    display: inline;
    position: relative;
    top: 3px;
    left: 3px;
  }
}
.image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: @space-2 0;
}

.signatrueMask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;

  .signatrue {
    position: relative;
    flex: 1;
    background-color: white;

    .canvas {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 44px;
    }

    .btns {
      position: absolute;
      top: 50px;
      left: 0;
      right: 0;
      display: flex;
      transform: rotate(90deg);
      transform-origin: left bottom;

      .btn {
        width: 50%;
        margin: 0 @space-1;
      }
    }
  }
}
.btn {
  width: 80%;
  margin: @space-0 auto;
  margin-bottom: 0;
}
</style>
